html, body {
    height: 100%;
    margin: 0;
    padding: 0;
    font-size: 18px;
}

body {
    background: #000;
    color: #999;
}

header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    max-height: 500px;
    min-height: 50px;
    overflow: auto; /* Enable scrolling if content exceeds max-height */
    z-index: 1000;
    background-image: linear-gradient(to bottom, #8C39DC, #230344);
}

.header-wrapper {
    display: flex;
    align-items: center; /* Align items vertically */
    justify-content: left; /* Center images horizontally */
    flex-wrap: nowrap; /* Prevent wrapping of items */
    overflow-x: auto; /* Allow horizontal scrolling */
}

.img-logo, .img-title {
    flex-shrink: 0; /* Prevents the images from shrinking */
    min-width: 50px; /* Minimum width for visibility */
    height: auto; /* Maintain aspect ratio */
}

.main {
    padding: 20px 0;
    width: 80%; /* Adjust as needed */
    height: calc(100% - 20px); /* Subtract the margin-top and margin-bottom */
}

.img-logo {
    width: 10%;
    max-height: 30%;
}

.img-title {
    width: 20%;
    max-height: 30%;
}

.stars {
    position: relative;
    bottom: 2px;
    margin: 0 15px;
}

.first-element {
    padding-top: 140px;
    padding-bottom: 30px;
}

.full-width-wrapper {
    width: 100%;
    display: block; /* Ensures it takes full width */
    background: #fff;
}

iframe {
    width: 100%; /* or any specific width */
    margin: 0 auto; /* centers the iframe horizontally */
    display: block; /* ensures the margin auto is effective */
}

.inverse {
    color: #D0BFE0;
    background: #000;
}

.more-button {
    margin-bottom: 20px;
    border: 1px solid #fff;
    background: transparent;
    color: #fff;
}

@media (min-width: 768px) {
    .img-logo {
        width: 140px;
    }

    .img-title {
        width: 300px;
    }

    .first-element {
        padding-top: 140px;
    }

}

@media (max-width: 767px) {
    .img-logo {
        width: 100px;
    }

    .img-title {
        width: 260px;
    }

    .first-element {
        padding-top: 110px;
    }
}

.default {
    display: block;
}

.mobile {
    display: none;
}


.bullets li {
    margin-bottom: 10px;
    color: #8c39dc;
}

.bullets.emphasis li {
    color: darkred;
}

.widget {

}



@media (max-width: 400px) {

    .default {
        display: none;
    }

    .mobile {
        display: block;
    }

    .img-logo {
        width: 50px;
        position: relative;
        left: 5px;
    }

    .img-title {
        width: 240px;
        position: relative;
        top: 5px;
        left: 10px;
    }

    .main {
        margin: 0 20px 20px 20px;
        width: 90%;
    }
}

.quotes {
    font-size: 16px; /* Reduced font size */
    width: 80%; /* Adjust width to make it a bit smaller */
    margin: 0 auto; /* Center the quotes */
    position: relative; /* For positioning the pseudo-elements */
    padding: 20px; /* Add some padding around the text */
    line-height: 2;
}

.flip-horizontal {
    transform: scaleX(-1); /* Flips the SVG horizontally */
}

.footer, .footer-bottom {
    background: #8C39DC;
    color: #fff;
}

.footer {
    margin-top: 40px;
    border-top: 1px solid #D0BFE0;
    background: linear-gradient(
            to top  ,
            rgba(0, 0, 0, 0.1) 0%, /* Start with black at 10% opacity at the top */
            rgba(0, 0, 0, 0.1) 2%, /* Continue with 10% opacity till 10% of the element */
            #8c39dc 100% /* Start transitioning to solid color from 10% */
    );
    color: #fff;
}