.carousel-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    height: 400px;
    position: relative;
}

.carousel-slide img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image covers the container and maintains aspect ratio */
    object-position: center; /* Centers the image */
}

.carousel-slide .fi {
    position: absolute;
    bottom:-5px;
    right:-5px;
    height: 100px;
    width: 150px;
}

@media (max-width: 600px) {
    .carousel-slide .fi {
        position: absolute;
        bottom:-8px;
        right:5px;
        height: 100px;
        width: 100px;
    }
}

.title-bar {
    background: darkred;
    color: white;
    text-align: center;
    font-size: 40px;
    width: 100%;
    height: 20%;
    position: relative;
}

.title-bar span {
    position: relative;
    top: 8px;
}

.modalwrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal {
    background: white;
    border-radius: 8px;
    position: relative;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
}

.close-button {
    position: absolute;
    z-index: 3;
    border: 2px solid #fff;
    top: 0;
    right: 0;
    background: #000;
    color: #fff;
    font-size: 40px;
    cursor: pointer;
    width: 60px;
    height: 60px;
    padding: 10px;
}

.close-button span {
    position: relative;
    bottom: 15px;
}

.modal-content {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.arrow {
    top: 200px;
    width: 50px;
    height: 98%;
    display: flex !important;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5); /* Adjust the background as needed */
    cursor: pointer;
    z-index: 1;
}

.arrow-next {
    right: 0;
}

.arrow-prev {
    left: 0;
}

.arrow::before {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
}

.arrow-next::before {
    border-width: 15px 0 15px 15px;
    border-color: transparent transparent transparent white;
}

.arrow-prev::before {
    border-width: 15px 15px 15px 0;
    border-color: transparent white transparent transparent;
}

